<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs
          class="rounded elevation-1"
          style="overflow: hidden"
          color="indigo"
          height="60px"
          grow
        >
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :to="tab.route"
            link
            exact-path
          >
            <div class="text-subtitle-1">{{ tab.name }}</div>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-scroll-x-reverse-transition mode="out-in">
        <router-view></router-view>
      </v-scroll-x-reverse-transition>
    </v-row>
  </v-container>
</template>

<script>

export default {
  computed: {
    tabs () {
      return [
        { route: { name: 'ImageConvert' }, name: '转换图片格式' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
